.app {
  text-align: center;
}

.board {
  position: relative;
  width: 100%;
  max-width: 700px;
}

.board:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.content {
  width: 100%;
  height: 100%;
}

.board-row {
  height: 12.5%;
}

.square {
  width: 12.5%;
}

.square-black {
  background-color: black;
}

.square-white {
  background-color: whitesmoke;
}

.square-selected {
  background-image: linear-gradient(
    to right,
    rgba(255, 0, 0, 0.5),
    rgba(255, 0, 0, 0.5)
  );
}

.square-valid {
  background-image: linear-gradient(
    to right,
    rgba(0, 255, 0, 0.5),
    rgba(0, 255, 0, 0.5)
  );
}

.square-previous {
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 0, 0.5),
    rgba(255, 255, 0, 0.5)
  );
}

.sphere {
  height: 80%;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 80%;
  margin: 10%;
}

.sphere-white {
  background-color: whitesmoke;
  border: 2px solid #d4af37;
}

.sphere-black {
  background-color: black;
  border: 2px solid #d4af37;
}

.tetrahedron {
  height: 80%;
  width: 80%;
  margin: 10%;
}

.pyramid {
  height: 80%;
  width: 80%;
  margin: 10%;
}

.cube {
  height: 80%;
  width: 80%;
  margin: 10%;
}
